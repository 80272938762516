const Api = {
  /**
   * Get environment of the current page
   * @returns {String} - environement (local, staging, qa, or production)
   */
  getEnv() {
    const url = document.URL
    const domain = window.location.hostname
    const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    let env = 'production'

    if (domain.indexOf('.local') >= 0 || domain === 'localhost' || url.indexOf('file://') >= 0 || ipAddressRegex.test(domain)) {
      env = 'local'
    } else if (domain.indexOf('staging.') >= 0) {
      env = 'staging'
    } else if (domain.indexOf('qa.') >= 0) {
      env = 'qa'
    } else {
      env = 'production'
    }

    return env
  },

  /**
   * Get api domain
   * @returns {String} - api domain
   */
  getDomain() {
    let domain = ''
    const env = this.getEnv()

    if (env === 'local') {
      domain = 'https://api.hilstaging.com'
    } else if (env === 'staging' || env === 'qa') {
      domain = 'https://api.hilstaging.com'
    } else {
      domain = 'https://api.modernize.com'
    }

    domain += '/v1/'

    return domain
  },

  /**
   * Get QMP API domain
   * DEV --> https://hsleadpost.dev.quinstreet.net/api?
   * TEST --> https://hsleadpost.test.quinstreet.net/api?
   * STAGE --> https://hsleadpost1.quinstage.com/api?
   *
   * @returns {String} - API domain
   */
  getQmpDomain() {
    let domain = ''
    const env = this.getEnv()

    if (env === 'local' || env === 'staging' || env === 'qa') {
      domain = 'https://hsleadpost1.quinstage.com/api/'
    } else {
      domain = 'https://hs.leadpost.net/api/'
    }

    return domain
  },

  /**
   * Get QMP API key
   * "DEV": "be54512d2b06529336e6324e453ad54a82f47caa833f7ede50aab6cd73b41b91",
   * "TEST": "be54512d2b06529336e6324e453ad54a82f47caa833f7ede50aab6cd73b41b91",
   * "STAGE": "2aa15245372e115e63c61567145109c53b1128d4a72ad65f977a358e1c00b96b",
   * "PROD": "b661851efb283200edad2431d1c369fa1c7e28d96dec354a74a251960489b06c"
   *
   * @returns {String} - API key
   */
  getQmpApiKey() {
    let xApiKey = ''
    const env = this.getEnv()

    if (env === 'local' || env === 'staging' || env === 'qa') {
      xApiKey = '2aa15245372e115e63c61567145109c53b1128d4a72ad65f977a358e1c00b96b'
    } else {
      xApiKey = 'b661851efb283200edad2431d1c369fa1c7e28d96dec354a74a251960489b06c'
    }

    return xApiKey
  },

  /**
   * Get modernize domain
   * @returns {String} - modernize domain
   */
  getModDomain() {
    let domain = ''
    const env = this.getEnv()

    if (env === 'local') {
      domain = 'http://modernize.local/'
    } else if (env === 'staging' || env === 'qa') {
      domain = 'https://qa.modernize.com/'
    } else {
      domain = 'https://modernize.com/'
    }

    return domain
  },

  /**
   * Get X-API-Key for client profiles requests
   * Can be obtained in client-profiles.configuration.api_key, "Modernize" name
   * @returns {String} - API key
   */
  getCpApiKey() {
    const env = this.getEnv()
    let apiKey

    if (['local', 'qa', 'staging'].indexOf(env) > -1) {
      apiKey = '1e4tSl7lW3O0CqFEsiwgiZzt8GqlMuuXp67Fk1Da'
    } else {
      apiKey = '1e4tSl7lW3O0CqFEsiwgiZzt8GqlMuuXp67Fk1Da'
    }

    return apiKey
  },

  /**
   * Get Client Profiles microservice domain
   * @returns {String} - API domain
   */
  getCpDomain() {
    return `${this.getDomain()}client-profiles/`
  },

  isQmpProjectId(projectId) {
    return projectId.length > 16
  }
}

export default Api
