import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { oktaAuthConfig, oktaSignInConfig } from './config'
import Header from './components/Header/Header'
import Login from './views/Login/Login'
import Home from './views/Home/Home'
import Review from './views/Reviews/Review'
import Reviews from './views/Reviews/Reviews'

import './App.css'

const oktaAuth = new OktaAuth(oktaAuthConfig)

const AppWithRouterAccess = () => {
  const history = useHistory()

  const customAuthHandler = () => {
    history.push('/login')
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Header />

      <Switch>
        <Route path="/" exact={true} component={Home} />
        <SecureRoute path="/reviews/:reviewId" component={Review} />
        <SecureRoute path="/reviews" component={Reviews} />
        <Route path="/login" render={() => <Login config={oktaSignInConfig} />} />
        <Route path="/login/callback" component={LoginCallback} />
      </Switch>
    </Security>
  )
}

export default AppWithRouterAccess
