import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonGroup, TextField } from '@material-ui/core'

function ReviewIdForm() {
  const reviewIdRef = React.useRef()

  const goToReview = () => {
    history.push(`/reviews/${reviewIdRef.current.value}`)
  }

  let history = useHistory()

  return (
    <ButtonGroup aria-label="Text button group">
      <TextField
        label="Jump to review by ID"
        variant="outlined"
        inputRef={reviewIdRef}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={goToReview}
      >
        Go
      </Button>
    </ButtonGroup>
  )
}

export default ReviewIdForm
