import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  logoutBtn: {
    marginLeft: theme.spacing(2),
    borderColor: '#FFF',
    color: '#FFF',
  }
}))

function Header() {
  const classes = useStyles()
  const { authState, oktaAuth } = useOktaAuth()

  const logoutHandler = () => {
    oktaAuth.signOut()
  }

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Client Profiles Admin
          </Typography>

          {authState && authState.isAuthenticated && (
            <>
              {authState.accessToken.claims.sub}
              <Button onClick={logoutHandler} size="small" variant="outlined" className={classes.logoutBtn}>
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
