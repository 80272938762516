import React from 'react'
import { useHistory } from 'react-router-dom'

const Home = () => {
  let history = useHistory()

  history.push('/reviews')

  return (
    <>
    </>
  )
}

export default Home
