import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import queryString from 'query-string';
import Api from '../../utils/Api'

import { Button, Container, Paper, Tabs, Tab, Typography } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles'

import ReviewIdForm from '../../components/Reviews/ReviewIdForm'

const apiDomain = Api.getCpDomain()
const apiKey = Api.getCpApiKey()
const defaultPageSize = 25

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

function loadReviewsFromApi(opts) {
  const { pageSize, reviewStatus, orderDirection } = opts
  let { orderField, page } = opts

  page += 1

  return new Promise((resolve) => {
    const url = `${apiDomain}reviews`
    const params = {
      'filters[status]': reviewStatus,
      page,
      pageSize
    }
    const headers = {
      'X-API-Key': apiKey
    }

    if (orderField === 'clientName') {
      orderField = 'c.name'
    }

    params[`orderBy[${orderField}]`] = orderDirection

    if (reviewStatus === 'approved') {
      params['filters[withCommentsOnly]'] = 1
    }

    axios.get(url, {
        headers,
        params
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        resolve({})
      })
  })
}

function Reviews(props) {
  const classes = useStyles()
  const columns = [{
    headerName: 'Client',
    width: 150,
    field: 'clientName',
    filterable: false,
    hideSortIcons: false,
    resizable: false,
    sortable: true
  }, {
    headerName: 'Score',
    width: 120,
    field: 'score',
    filterable: false,
    hideSortIcons: false,
    resizable: false,
    sortable: true,
    renderCell: (params) => {
      return <Rating name="read-only" value={params.value} size="small" readOnly />
    }
  }, {
    headerName: 'Comment',
    width: 200,
    field: 'comment',
    filterable: false,
    hideSortIcons: true,
    resizable: false,
    sortable: false
  }, {
    headerName: 'Photos',
    width: 100,
    field: 'photos',
    filterable: false,
    hideSortIcons: true,
    resizable: false,
    sortable: false,
    valueFormatter: (params) => {
      const photosCount = params.value.data.length

      return photosCount > 0 ? `${photosCount} photo${photosCount > 1 ? 's' : ''}` : '-'
    }
  }, {
    headerName: 'Date',
    width: 120,
    field: 'date',
    filterable: false,
    hideSortIcons: false,
    resizable: false,
    sortable: true
  }, {
    headerName: 'Updated',
    width: 170,
    field: 'updatedTs',
    filterable: false,
    hideSortIcons: true,
    resizable: false,
    sortable: false
  }, {
    headerName: 'Actions',
    width: 120,
    field: 'reviewId',
    filterable: false,
    hideSortIcons: true,
    resizable: false,
    sortable: false,
    renderCell: (params) => (
      <Button component={Link} to={`/reviews/${params.value}`} variant="contained" color="primary" size="small">Review</Button>
    ),
  },]
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState([])
  const [rowCount, setRowCount] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(defaultPageSize)
  const [orderField, setOrderField] = React.useState('updatedTs')
  const [orderDirection, setOrderDirection] = React.useState('desc')
  const [loading, setLoading] = React.useState(false)
  const queryParams = queryString.parse(props.location.search)
  const reviewStatus = queryParams.status ?? 'pending'

  let history = useHistory()

  const handlePageChange = (page) => {
    setPage(page)
  }

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize)
  }

  const handleRowSelection = (row) => {
    history.push(`/reviews/${row.data.reviewId}`)
  }

  const handleSortModelChange = (sortsBy) => {
    if (!sortsBy || !sortsBy[0]) {
      return
    }

    setOrderField(sortsBy[0].field)
    setOrderDirection(sortsBy[0].sort)
  }

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true)
      const reviews = await loadReviewsFromApi({
        page,
        pageSize,
        reviewStatus,
        orderField,
        orderDirection,
      })

      if (!active) {
        return
      }

      setRows(reviews.data || [])
      setRowCount(reviews.meta ? reviews.meta.pagination.total : 0)
      setPageSize(reviews.meta ? reviews.meta.pagination.perPage : defaultPageSize)
      setLoading(false)
    })()

    return () => {
      active = false
    }
  }, [page, pageSize, reviewStatus, orderField, orderDirection])

  return (
    <>
      <Helmet title="Reviews" />

      <main>
        <Container maxWidth="lg">
          <Paper className={classes.paper}>
            <Typography variant="h6" component="div" gutterBottom>
              Reviews
            </Typography>

            <ReviewIdForm />

            <Tabs
              aria-label="Review tabs"
              value={reviewStatus}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab component={Link}
                value="pending"
                to={`/reviews`}
                label="Pending"
              />
              <Tab component={Link}
                value="rejected"
                to={`/reviews?status=rejected`}
                label="Rejected"
              />
              <Tab component={Link}
                value="approved"
                to={`/reviews?status=approved`}
                label="Approved"
              />
            </Tabs>

            <div style={{ minHeight: 300, width: '100%' }}>
              <DataGrid
                autoHeight
                pagination
                rows={rows}
                getRowId={(r) => r.reviewId}
                columns={columns}
                pageSize={pageSize}
                rowCount={rowCount}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onRowSelected={handleRowSelection}
                loading={loading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </div>
          </Paper>
        </Container>
      </main>
    </>
  )
}

export default Reviews
