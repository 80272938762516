import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import Api from '../../utils/Api'
import { useOktaAuth } from '@okta/okta-react'
import { Box, Button, ButtonGroup, Checkbox, CircularProgress, Container, FormControlLabel, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Paper, Snackbar, Typography } from '@material-ui/core'
import { Alert, Rating } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'

const qmpApiDomain = Api.getQmpDomain()
const qmpKey = Api.getQmpApiKey()
const apiDomain = Api.getDomain()
const apiCpDomain = Api.getCpDomain()
const apiKey = Api.getCpApiKey()

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  photoButtons: {
    backgroundColor: '#FFF',
    marginRight: theme.spacing(1),
  }
}))

function loadReviewFromApi(reviewId) {
  return new Promise((resolve) => {
    const url = `${apiCpDomain}reviews/${reviewId}`
      const headers = {
        'X-API-Key': apiKey
      }

      axios
        .get(url, {
          headers
        })
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => {
          resolve([])
        })
    })
}

function updateReviewInApi(reviewId, data) {
  return new Promise((resolve) => {
    const url = `${apiCpDomain}reviews/${reviewId}`
      const headers = {
        'X-API-Key': apiKey
      }

      axios
        .put(url, data, {
          headers
        })
        .then(response => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
}

function updateReviewPhotoInApi(reviewId, photoId, data) {
  return new Promise((resolve) => {
    const url = `${apiCpDomain}reviews/${reviewId}/photos/${photoId}`
      const headers = {
        'X-API-Key': apiKey
      }

      axios
        .put(url, data, {
          headers
        })
        .then(response => {
          resolve()
        })
        .catch(error => {
          resolve()
        })
    })
}

function loadProjectDetailsFromApi(projectId) {
  return new Promise((resolve) => {
    const url = `${qmpApiDomain}projects/${projectId}`
      const headers = {
        'X-API-Key': qmpKey
      }

      axios
        .get(url, {
          headers
        })
        .then(response => {
          resolve(response.data ? response.data : null)
        })
        .catch(error => {
          resolve(null)
        })
    })
}

function Review() {
  const { authState } = useOktaAuth()
  const classes = useStyles()
  const [review, setReview] = useState({})
  const [project, setProject] = useState({})
  const [loading, setLoading] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [shouldApplyReviewStatusToPhoto, setShouldApplyReviewStatusToPhoto] = useState(true)
  const allTags = [
    'Friendly',
    'Responsive',
    'Great Service',
    'Professional',
    'Budget Friendly',
    'On Time',
    'Trustworthy',
    'Informative'
  ]
  const reviewedBy = authState.accessToken.claims.sub

  let { reviewId } = useParams()

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true)
      const review = await loadReviewFromApi(reviewId)

      if (!active) {
        return
      }

      if (review.projectId) {
        const project = await loadProjectDetailsFromApi(review.projectId)

        if (project) {
          setProject(project)
        }
      }

      setReview(review)
      setLoading(false)
    })()

    return () => {
      active = false
    }
  }, [reviewId])

  const handlePhotoStatusBtnClick = (reviewId, photoId, status) => {
    const data = {
      status,
      reviewedBy
    }

    review.photos.data.map((photo, index) => {
      if (photo.mediaId === photoId) {
        review.photos.data[index].status = status
      }

      return true
    })

    setReview({ ...review })

    updateReviewPhotoInApi(reviewId, photoId, data)
    setSnackbarOpen(true)
  }

  const handleReviewStatusBtnClick = (status) => {
    const data = {
      status,
      reviewedBy
    }

    review.status = status

    if (shouldApplyReviewStatusToPhoto) {
      data.mediaStatus = status

      review.photos.data.map((photo, index) => {
        review.photos.data[index].status = status

        return true
      })
    }

    setReview({ ...review })

    updateReviewInApi(reviewId, data)
    setSnackbarOpen(true)
  }

  const handleTagChange = (event) => {
    const { checked, value } = event.target

    if (checked) {
      review.tags.push(value)
    } else {
      review.tags = review.tags.filter(function(tag) {
        return tag !== value
      })
    }

    setReview({ ...review })

    updateReviewInApi(reviewId, {
      tags: review.tags
    })
    setSnackbarOpen(true)
  }

  const handleShouldApplyReviewStatusToPhotoChange = (event) => {
    setShouldApplyReviewStatusToPhoto(event.target.checked)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <>
      <Helmet title="Review" />

      <main>
        <Container maxWidth="lg">
          <Paper className={classes.paper}>
            <Typography variant="h6" component="div" gutterBottom>
              Review #{reviewId}
            </Typography>

            <Typography gutterBottom>
              <Button component={Link} to="/reviews" variant="outlined" color="primary" className={classes.button}>
                <ChevronLeftRoundedIcon /> Back to reviews
              </Button>
            </Typography>

            {loading && <CircularProgress />}

            {!loading && !review.reviewId && (
              <>
                <Alert severity="error">
                  Review #{reviewId} not found.
                </Alert>
              </>
            )}

            {!loading && review.reviewId && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Review ID
                      </Typography>
                      {review.reviewId}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Status
                      </Typography>
                      {review.status.charAt(0).toUpperCase() + review.status.slice(1)}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Stage
                      </Typography>
                      {review.stage.data.name}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Client
                      </Typography>
                      {review.clientName}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Score
                      </Typography>
                      <Rating name="read-only" value={review.score} size="small" readOnly />
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Comment
                      </Typography>
                      {review.comment}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Homeowner name
                      </Typography>
                      {project && project.homeowner && (`${project.homeowner.firstName} ${project.homeowner.lastName}`)}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Homeowner email
                      </Typography>
                      {project && project.homeowner && (`${project.homeowner.email}`)}
                    </Box>

                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Photos
                      </Typography>

                      {review.photos.data.length > 0 && (
                        <ImageList>
                          {review.photos.data.map((photo) => (
                            <ImageListItem key={photo.mediaId}>
                              <a href={photo.url} target="_blank" rel="noreferrer">
                                <img src={photo.url} alt={photo.description} loading="lazy" />
                              </a>

                              <ImageListItemBar
                                title=""
                                subtitle=""
                                actionIcon={
                                  <ButtonGroup className={classes.photoButtons}>
                                    <Button variant={photo.status === 'approved' ? 'contained' : 'outlined'} size="small" color="primary"
                                      onClick={() => handlePhotoStatusBtnClick(reviewId, photo.mediaId, 'approved')
                                    }>
                                      Approve
                                    </Button>
                                    <Button variant={photo.status === 'rejected' ? 'contained' : 'outlined'} size="small" color="secondary"
                                      onClick={() => handlePhotoStatusBtnClick(reviewId, photo.mediaId, 'reject')
                                    }>
                                      Reject
                                    </Button>
                                  </ButtonGroup>
                                }
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box component="fieldset" mb={1} borderColor="transparent">
                      <Typography color="textSecondary" gutterBottom>
                        Tags
                      </Typography>

                      {allTags.map((tag, index) => {
                        return (
                          <div key={tag}>
                            <FormControlLabel control={
                              <Checkbox name="tag[]" value={tag} checked={review.tags.indexOf(tag) > -1} onChange={handleTagChange} />
                            } label={tag} />
                          </div>
                        )
                      })}
                    </Box>
                  </Grid>
                </Grid>

                <ButtonGroup className={classes.button}>
                  <Button variant={review.status === 'approved' ? 'contained' : 'outlined'} color="primary"
                    onClick={() => handleReviewStatusBtnClick('approved')
                  }>
                    Approve review
                  </Button>
                  <Button variant={review.status === 'rejected' ? 'contained' : 'outlined'} color="secondary"
                    onClick={() => handleReviewStatusBtnClick('rejected')
                  }>
                    Reject review
                  </Button>
                </ButtonGroup>

                <FormControlLabel control={
                  <Checkbox name="applyStatusToPhotos" checked={shouldApplyReviewStatusToPhoto} onChange={handleShouldApplyReviewStatusToPhotoChange} />
                } label="Apply to photos" />
              </>
            )}
          </Paper>
        </Container>
      </main>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message="Review has been updated"
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.snackbarClose}
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  )
}

export default Review
